// src/components/CaseStudies.js
import React, { useEffect, useRef, useState } from "react";

import "../static/CaseStudies.css";

import wildland from "../images/fire_biz.png"; // Importing the image
import bakery from "../images/bakery.jpg"; // Importing the image
import bazaar from "../images/bazaar.jpg"; // Importing the image
import auraT from "../images/aura_transport.jpeg"; // Importing the image

const CaseStudies = () => {
  const [isVisible1, setVisible1] = useState(false);
  const [isVisible2, setVisible2] = useState(false);
  const [isVisible3, setVisible3] = useState(false);
  const [isVisible4, setVisible4] = useState(false);

  const domRef1 = useRef();
  const domRef2 = useRef();
  const domRef3 = useRef();
  const domRef4 = useRef();

  useEffect(() => {
    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible1(true);
          observer1.unobserve(entry.target);
        }
      });
    });

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible2(true);
          observer2.unobserve(entry.target);
        }
      });
    });

    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible3(true);
          observer3.unobserve(entry.target);
        }
      });
    });

    const observer4 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible4(true);
          observer4.unobserve(entry.target);
        }
      });
    });

    observer1.observe(domRef1.current);
    observer2.observe(domRef2.current);
    observer3.observe(domRef3.current);
    observer4.observe(domRef4.current);

    // Cleanup function to disconnect observers
    return () => {
      observer1.disconnect();
      observer2.disconnect();
      observer3.disconnect();
      observer4.disconnect();
    };
  }, []);

  return (
    <div className="caseStudies_div">
      <h1>Case Studies</h1>
      <div className="caseStudies_inner_div">
        {" "}
        <div
          className={`study fade-in-section ${isVisible2 ? "is-visible" : ""}`}
          ref={domRef2}
        >
          <h2>Wildland Security</h2>
          <img className="company_img" src={wildland} alt=""></img>
          <p>
            Viral Vision helped Wildland Security boost their online presence.
            The company faced challenges with low search rankings and outdated
            marketing strategies. Viral Vision redesigned their website for
            better SEO, created engaging content, and improved their social
            media presence with targeted ads. They also highlighted positive
            client reviews.
            <br />
            <br />
            This led to a 60% increase in organic traffic, a 40% rise in client
            inquiries, and a 75% growth in social media followers, enhancing
            Wildland Security's visibility and engagement.
          </p>
        </div>
        <div
          className={`study fade-in-section ${isVisible1 ? "is-visible" : ""}`}
          ref={domRef1}
        >
          <h2>Nuvrei</h2>
          <img className="company_img" src={bakery} alt=""></img>
          <p>
            Viral Vision partnered with Nuvrei, a charming petite bakery, to
            elevate their digital presence. Nuvrei faced challenges with limited
            online visibility and a lackluster marketing strategy. Viral Vision
            focused on enhancing Nuvrei's social media presence with visually
            appealing posts showcasing their baked goods and engaging with the
            local community. They also ran targeted social media ads to attract
            new customers and promoted special offers and seasonal treats.
            Additionally, they highlighted positive customer reviews to build
            credibility.
            <br />
            <br />
            As a result, Nuvrei saw a 55% increase in social media engagement, a
            35% rise in customer inquiries, and a 45% boost in foot traffic to
            their bakery, significantly enhancing their local visibility and
            customer connection.
          </p>
        </div>
        <div
          className={`study fade-in-section ${isVisible3 ? "is-visible" : ""}`}
          ref={domRef3}
        >
          <h2>Aura Transport</h2>
          <img className="company_img" src={auraT} alt=""></img>
          <p>
            Viral Vision partnered with Aura Transport to elevate their digital
            footprint. Faced with challenges of limited online visibility and a
            dated marketing strategy, Aura Transport needed a fresh approach.
            Viral Vision implemented a complete website overhaul, integrating
            advanced SEO techniques to boost search engine rankings. They
            created dynamic content and optimized social media campaigns to
            better engage with potential clients. Additionally, they emphasized
            positive client feedback to build credibility.
            <br />
            <br />
            This strategy led to a 55% increase in website traffic, a 45% boost
            in service inquiries, and a 70% rise in social media followers,
            significantly improving Aura Transport's online presence and client
            engagement.
          </p>
        </div>
        <div
          className={`study fade-in-section ${isVisible4 ? "is-visible" : ""}`}
          ref={domRef4}
        >
          <h2>Bazaar Market</h2>
          <img className="company_img" src={bazaar} alt=""></img>
          <p>
            Viral Vision collaborated with Bazaar Food Market, a local
            Mediterranean and Muslim market, to enhance their digital presence.
            The market struggled with low online visibility and an outdated
            marketing approach. Viral Vision developed a targeted strategy that
            included local SEO optimization to improve search engine rankings,
            created engaging social media content to connect with the community,
            and ran promotional campaigns highlighting special offers and unique
            products. They also focused on gathering and showcasing positive
            customer reviews.
            <br />
            <br />
            This approach led to a 50% increase in online engagement, a 40%
            boost in customer inquiries, and a 60% growth in social media
            followers, significantly improving Bazaar Food Market's local
            visibility and customer interaction.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
