// src/components/Home.js
import React, { useEffect, useRef, useState } from "react";

import logo from "../images/sss.jpg"; // Importing the image
import decisions from "../images/decisions.png"; // Importing the image
import strategies from "../images/strategies.png"; // Importing the image
import results from "../images/results.jpg"; // Importing the image

import seo from "../images/seo.jpg"; // Importing the image
import content_marketing from "../images/content_marketing.jpg"; // Importing the image
import web_design from "../images/web_design.png"; // Importing the image
import ppc from "../images/ppc.png"; // Importing the image
import social_media from "../images/social_media.png"; // Importing the image
import email_marketing from "../images/email-blast.png";

// CSS
import "../static/Home.css";

const Home = () => {
  const [isVisible1, setVisible1] = useState(false);
  const [isVisible2, setVisible2] = useState(false);
  const [isVisible3, setVisible3] = useState(false);
  const [isVisible4, setVisible4] = useState(false);
  const [isVisible5, setVisible5] = useState(false);
  const [isVisible6, setVisible6] = useState(false);
  const [isVisible7, setVisible7] = useState(false);
  const [isVisible8, setVisible8] = useState(false);
  const [isVisible9, setVisible9] = useState(false);
  const [isVisible10, setVisible10] = useState(false);
  const [isVisible11, setVisible11] = useState(false);
  const [isVisible12, setVisible12] = useState(false);

  const domRef1 = useRef();
  const domRef2 = useRef();
  const domRef3 = useRef();
  const domRef4 = useRef();
  const domRef5 = useRef();
  const domRef6 = useRef();
  const domRef7 = useRef();
  const domRef8 = useRef();
  const domRef9 = useRef();
  const domRef10 = useRef();
  const domRef11 = useRef();
  const domRef12 = useRef();

  useEffect(() => {
    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible1(true);
          observer1.unobserve(entry.target);
        }
      });
    });

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible2(true);
          observer2.unobserve(entry.target);
        }
      });
    });

    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible3(true);
          observer3.unobserve(entry.target);
        }
      });
    });

    const observer4 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible4(true);
          observer4.unobserve(entry.target);
        }
      });
    });
    const observer5 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible5(true);
          observer5.unobserve(entry.target);
        }
      });
    });
    const observer6 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible6(true);
          observer6.unobserve(entry.target);
        }
      });
    });

    const observer7 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible7(true);
          observer7.unobserve(entry.target);
        }
      });
    });

    const observer8 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible8(true);
          observer8.unobserve(entry.target);
        }
      });
    });
    const observer9 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible9(true);
          observer9.unobserve(entry.target);
        }
      });
    });
    const observer10 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible10(true);
          observer10.unobserve(entry.target);
        }
      });
    });
    const observer11 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible11(true);
          observer11.unobserve(entry.target);
        }
      });
    });
    const observer12 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible12(true);
          observer12.unobserve(entry.target);
        }
      });
    });

    observer1.observe(domRef1.current);
    observer2.observe(domRef2.current);
    observer3.observe(domRef3.current);
    observer4.observe(domRef4.current);
    observer5.observe(domRef5.current);
    observer6.observe(domRef6.current);
    observer7.observe(domRef7.current);
    observer8.observe(domRef8.current);
    observer9.observe(domRef9.current);
    observer10.observe(domRef10.current);
    observer11.observe(domRef11.current);
    observer12.observe(domRef12.current);

    // Cleanup function to disconnect observers
    return () => {
      observer1.disconnect();
      observer2.disconnect();
      observer3.disconnect();
      observer4.disconnect();
      observer5.disconnect();
      observer6.disconnect();
      observer7.disconnect();
      observer8.disconnect();
      observer9.disconnect();
      observer10.disconnect();
      observer11.disconnect();
      observer12.disconnect();
    };
  }, []);

  return (
    <div className="home">
      <div className="sss">
        <img className="slideLeft" src={logo} alt="Viral Vision" />
        <div className="slideRight home_info">
          <p>Boost Your Brand with Cutting-Edge Digital Marketing</p>
          <a href="/contact">
            <p>Get a Free Consultation</p>
          </a>
        </div>
      </div>
      <div className="gray_background">
        <section className="about_us">
          <h2 className="home_page_h2">Our Mission</h2>
          <div
            className={`inner_home_content fade-in-section ${
              isVisible1 ? "is-visible" : ""
            }`}
            ref={domRef1}
          >
            <p>
              At Viral Vision, we are dedicated to helping businesses grow with
              innovative digital marketing strategies. Our team of experts
              brings a wealth of experience in SEO, PPC, social media, and
              content marketing.
            </p>

            <p>
              Our approach is rooted in creativity, precision, and a deep
              understanding of the ever-evolving digital landscape. We partner
              with businesses of all sizes to unlock their full potential
              through personalized marketing strategies that enhance their
              online presence and foster meaningful connections with their
              target audience.
            </p>

            <p>At Viral Vision, your success is our vision.</p>
          </div>
        </section>

        <section className="approach">
          <h2 className="home_page_h2">Our Approach</h2>
          <div className="inner_home_content">
            <ul>
              <li
                className={`fade-in-section ${isVisible4 ? "is-visible" : ""}`}
                ref={domRef4}
              >
                <img src={strategies} alt="" />
                <strong>Customized Strategies</strong>
                <p>
                  We don't believe in one-size-fits-all solutions. Each strategy
                  is tailored to fit your specific needs and goals.
                </p>
              </li>
              <li
                className={`fade-in-section ${isVisible5 ? "is-visible" : ""}`}
                ref={domRef5}
              >
                <img src={decisions} alt="" />
                <strong>Data-Driven Decisions</strong>
                <p>
                  Our decisions are informed by thorough research and analytics,
                  ensuring strategies are effective and results-driven.
                </p>
              </li>
              <li
                className={`fade-in-section ${isVisible6 ? "is-visible" : ""}`}
                ref={domRef6}
              >
                <img src={results} alt="" />
                <strong>Results-Oriented</strong>
                <p>
                  We focus on delivering measurable outcomes, whether it's
                  increased traffic, higher engagement, or improved conversions.
                </p>
              </li>
            </ul>
          </div>
        </section>
        <section className="clients_love">
          <h2 className="home_page_h2">Why Our Clients Love Us</h2>
          <div
            className={`inner_home_content fade-in-section ${
              isVisible2 ? "is-visible" : ""
            }`}
            ref={domRef2}
          >
            <ul>
              <li>
                <strong>Proven Results</strong>: Our clients see tangible
                improvements in their online performance
              </li>
              <li>
                <strong>Expert Team</strong>: Work with a team of experienced
                professionals who are passionate about digital marketing
              </li>
              <li>
                <strong>Transparent Communication</strong>: Stay informed with
                clear, honest communication and regular updates
              </li>
            </ul>
          </div>
        </section>

        <section className="services fade-in">
          <h2 className="home_page_h2">Our Solutions</h2>
          <div className="inner_home_content">
            <div className="service">
              <img
                className={`fade-section ${isVisible7 ? "is-visible" : ""}`}
                ref={domRef7}
                src={seo}
                alt="SEO"
              />
              <h3>SEO</h3>
              <p>
                Enhance your website's visibility and rankings with our SEO
                expertise.
              </p>
            </div>
            <div className="service">
              <img
                className={`fade-section ${isVisible8 ? "is-visible" : ""}`}
                ref={domRef8}
                src={web_design}
                alt="PPC"
              />
              <h3>Web Design</h3>
              <p>
                Drive targeted traffic and increase ROI with our PPC campaigns.
              </p>
            </div>
            <div className="service">
              <img
                className={`fade-section ${isVisible9 ? "is-visible" : ""}`}
                ref={domRef9}
                src={ppc}
                alt="PPC"
              />
              <h3>PPC Advertising</h3>
              <p>
                Drive targeted traffic and increase ROI with our PPC campaigns.
              </p>
            </div>

            <div className="service">
              <img
                className={`white-background fade-section ${
                  isVisible12 ? "is-visible" : ""
                }`}
                ref={domRef12}
                src={email_marketing}
                alt="Email Marketing"
              />
              <h3>Email Marketing</h3>
              <p>
                Boost engagement and conversions with our tailored email
                campaigns.
              </p>
            </div>

            <div className="service">
              <img
                className={`fade-section ${isVisible10 ? "is-visible" : ""}`}
                ref={domRef10}
                src={content_marketing}
                alt="PPC"
              />
              <h3>Content Marketing</h3>
              <p>
                Drive targeted traffic and increase ROI with our PPC campaigns.
              </p>
            </div>
            <div className="service">
              <img
                className={`fade-section ${isVisible11 ? "is-visible" : ""}`}
                ref={domRef11}
                src={social_media}
                alt="PPC"
              />
              <h3>Social Media Marketing</h3>
              <p>
                Drive targeted traffic and increase ROI with our PPC campaigns.
              </p>
            </div>
          </div>
        </section>
        <section className="testimonials">
          <h2 className="home_page_h2">Success Stories</h2>
          <div
            className={`inner_home_content fade-in-section ${
              isVisible3 ? "is-visible" : ""
            }`}
            ref={domRef3}
          >
            <div className="testimonial">
              <p>
                "We were lacking a social media presence and Viral Vision helped
                us a lot more clients through our website!"
              </p>
              <cite>— Jaxon, Aura Transport</cite>
            </div>
            <div className="testimonial">
              <p>
                "Viral Vision transformed our online presence and helped us
                achieve our sales goals. Highly recommended!"
              </p>
              <cite>— Mohamed, Bazaar Food Market</cite>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
